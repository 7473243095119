<script >
import {mapGetters} from "vuex";

export default {
  name : "UpdateAccountBanner",
  data(){
    return{

    }
  },
  computed:{
    ...mapGetters("paddler",["logInPaddler"])
  },
  methods:{

  }
}
</script>

<template>
  <div v-if="logInPaddler && logInPaddler.accountDetail && logInPaddler.accountDetail.accountUpdated === false" class="tw-flex tw-w-full  tw-p-2 tw-flex-col lg:tw-flex-row tw-justify-between" style="background: #EE0000; border-radius: 10px;">
    <v-btn   color="white" class="update-btn" @click="$router.push({name:'UpdateAccount'})">Update account</v-btn>
    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-start lg:tw-items-center lg:tw-justify-end tw-w-full">
      <div class="lg:tw-px-5 tw-py-3 lg:tw-py-0">
      <v-tooltip bottom color="black" >
        <template v-slot:activator="{ on, attrs }" class="">
          <v-icon  v-bind="attrs" color="white"
                   v-on="on">mdi-help-box</v-icon>
        </template>
        <div class="tw-flex tw-flex-col " >
          <h6 class="tip-text font-weight-bold">Why you should update your account details</h6>
          <div class="tw-py-4 tw-flex-col">
            <div class="tip-text">Due to the new Central Bank of Nigeria (CBN) policy,</div>
            <div class="tip-text">all existing Bank Accounts holders are required</div>
            <div class="tip-text">to provide the following to avoid account restriction.</div>
            <div class="tw-mt-3 tip-text">1) Image matching NIN</div>
            <div class="tip-text">2) NIN (National Identification Number)</div>
            <div class="tip-text">3) BVN (Bank Verification Number)</div>
          </div>
        </div>
      </v-tooltip>
      </div>
      <div  class="tw-flex tw-w-full lg:tw-w-auto lg:tw-mx-5">
        <v-btn  color="white" class="update-btn tw-w-full">Read more</v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.update-btn{
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  color: black;
}
.tip-text{
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  text-transform: none !important;
}
</style>